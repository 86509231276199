@import "../base/_colors"
.mainTable
  margin-bottom: 24px
  & .ant-table-wrapper
      table
        table-layout: fixed !important
        .ant-table-tbody > tr > td:not(:last-child, .noPadding)
          width: fit-content
          padding: 16px 60px 16px 16px
        .ant-table-tbody > tr > td:not(.actions-column):last-child
          width: 100%
          min-width: 170px
          text-align: right
        .ant-table-tbody > tr > td.noPadding
          padding-top: 0 !important
          padding-bottom: 0 !important
  & .ant-table .ant-table-container
    &::before, &::after
      display: none
    & .ant-table-tbody
        & .iconBox
          display: flex
          align-items: center
          justify-content: flex-end
          & > .icon 
            width: 30px
            height: 30px
            margin: 0  13px
            position: relative
            border-radius: 100%
            cursor: pointer
            &:before
              width: 18px
              height: 18px
              position: absolute
              left: 6px
              right: 6px
              top: 6px
              bottom: 6px
              margin: auto
            &:hover
              background: #F2F2F7 0 0 no-repeat padding-box
              &.edit-icon:before
                content: url(@assets/img/icon/edit1.svg)
              &.delete-icon:before
                content: url(@assets/img/icon/delete1.svg)
            &.disabled
              background: transparent
              cursor: not-allowed
              &:hover
                &.edit-icon:before
                  content: url(@assets/img/icon/edit.svg)
                &.delete-icon:before
                   content: url(@assets/img/icon/delete.svg)

        & .img
          width: 36px
          height: 36px
          background: #41479B 0 0 no-repeat padding-box
          border-radius: 2px
          padding: 6px
          opacity: 1
          object-fit: contain
          display: flex
          align-items: center
          justify-content: center
          color: #fff
          font-weight: 800
          & img
            width: 36px
            height: 36px
            padding: 6px
            object-fit: contain
        & .ant-table-row
          & .ant-table-cell
            & .prediction
              display: flex
              align-items: center
              & img
                margin-right: 14px
            & .success, & .win
              color: #00CE7E
            & .progress
              color: $project-global-color
            & .failed, & .lose
              color: #AA193B
            & .copyable
              cursor: pointer
            & .win_bg
              background-color: #edfbf1
            & .lose_bg
              background-color: #f6e8eb
            & .refund_bg
              background-color: #f8f8fb
  & .ant-table.ant-table-empty
    .ant-table-tbody > tr > td:last-child
      width: fit-content
      min-width: fit-content

  & .ant-pagination.ant-table-pagination.ant-table-pagination-right
      display: flex
      margin-top: 60px
      & .ant-pagination-total-text
          order: 20
          margin: 0 10px
  & .ant-table-wrapper 
      margin-top: 2px
  & .ant-table-thead > tr > th
      text-align: left
      font: 14px/19px "Red Hat Display Regular"
      letter-spacing: 0px
      color: $project-global-color
      white-space: nowrap
  & .ant-table-wrapper .ant-table-tbody > tr > td
      text-align: left
      font: 14px/19px "Red Hat Display Regular"
      letter-spacing: 0px
      color: $project-global-color
      white-space: nowrap
      &:not(.copyable-column)
        text-overflow: ellipsis
        overflow: hidden
      & .img
        width: 36px
        height: 36px
        background: #41479B 0% 0% no-repeat padding-box
        border-radius: 2px
        padding: 6px
        opacity: 1
  &.translation
    & .ant-table-tbody tr > td
      min-width: 173px !important
      &:nth-child(3)
        width: 100%
  &.clients
    & .ant-table-tbody tr > td
      max-width: 173px !important
  & .tableFooter
    padding: 12px
    display: flex
    align-items: flex-end
    justify-content: space-between
    @media only screen and (max-width: 1500px)
      flex-wrap: wrap
    & .profitList
      width: calc(100% / 2 - 16px)
      @media only screen and (max-width: 1500px)
        width: 100%
        display: flex
        align-items: center
        justify-content: flex-start
      & .list
        height: 66px
        display: flex
        flex-direction: column
        flex-wrap: wrap
        justify-content: flex-end
        width: fit-content
        & .item
          padding-right: 10px
          & .title
            text-align: left
            font: 600 14px/14px "Red Hat Display Regular"
            letter-spacing: 0
            color: $project-global-color
    & .ant-pagination
      display: flex
      justify-content: flex-end
      width: calc(100% / 2 - 16px)
      @media only screen and (max-width: 1500px)
        width: 100%
      & .ant-pagination-total-text
        order: 20
        margin: 0 10px
  & .tableActionsBox
      display: flex
      align-items: center
      justify-content: flex-end
      padding: 10px

body
  &.opened
    & .row-dragging
      background: #fafafa
      border: 1px solid #ccc
      width: calc(100vw - 466px) !important

.row-dragging 
  background: #fafafa
  border: 1px solid #ccc
  width: calc(100vw - 266px) !important
  overflow: hidden
  & td 
    padding: 16px
    visibility: hidden
  & .drag-visible 
    visibility: visible
    & .img
      width: 36px
      height: 36px
      background: #41479B 0% 0% no-repeat padding-box
      border-radius: 2px
      padding: 6px
      opacity: 1

.ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active
  color: $project-global-color

.text-right
  text-align: right !important