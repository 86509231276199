#api-key-form
    .middle-area
        width: 100%
        & > .ant-space-item
            width: 50%

    .ant-select-multiple .ant-select-selection-item
        background: #E6F7FF 0% 0% no-repeat padding-box
        border-radius: 2px

    .ant-select-multiple .ant-select-selection-item-content
        font: normal normal 14px/22px Red Hat Display
        color: $project-global-color

    .anticon-close
        color: $project-global-color