.ant-picker-cell .ant-picker-cell-inner
    color: rgba($project-global-color, 0.5)

.ant-picker-cell-in-view .ant-picker-cell-inner, .ant-picker-content th
    font: normal normal 14px/22px Red Hat Display
    color: $project-global-color
.ant-picker-header button
    color: $project-global-color
.ant-picker-date-panel .ant-picker-body
    padding-left: 0
    padding-right: 0
    border-bottom: 1px solid #DCDCE4
.ant-picker-decade-panel, .ant-picker-year-panel, .ant-picker-quarter-panel, .ant-picker-month-panel, .ant-picker-week-panel, .ant-picker-date-panel, .ant-picker-time-panel
    width: 264px
    background: transparent linear-gradient(180deg, #F8F8FA 0%, #F2F2F7 100%) 0% 0% no-repeat padding-box
    box-shadow: 0px 0px 15px #1a183b33
    border-top-left-radius: 2px
    border-top-right-radius: 2px
.ant-picker-time-panel
    width: auto
    min-width: auto
    border-bottom: 1px solid #DCDCE4
.ant-picker-header-view, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner
    font: normal normal 14px/22px Red Hat Display
    color: $project-global-color
.ant-picker-ranges
    display: flex
    padding: 0
    height: 42px
    .ant-picker-preset
        width: 132px
        background: transparent linear-gradient(180deg, #f2f2f7 0%, #f2f2f7 100%) 0% 0% no-repeat padding-box
        border-bottom-left-radius: 2px
        border-bottom-right-radius: 2px
        &:nth-child(2)
            margin-right: 4px
        .ant-tag
            margin: 0px
            width: 100%
            height: 100%
            padding: 0px
            border: unset
            background: transparent
            display: flex
            align-items: center
            justify-content: center
            font: normal normal 14px/22px Red Hat Display
            color: $project-global-color
            &:hover
                color: $periods-button-hover-text-color
            &:active
                color: $periods-button-active-text-color
                font: normal normal  14px/22px "Red Hat Display Bold"
    .ant-picker-ok
        margin: 0px
        padding: 0px
        width: 169px
        background: transparent linear-gradient(180deg, #f2f2f7 0%, #f2f2f7 100%) 0% 0% no-repeat padding-box
        box-shadow: 0px 0px 15px #1a183b33
        border-bottom-left-radius: 2px
        border-bottom-right-radius: 2px
        .ant-btn-primary
            width: 100%
            height: 100%
            padding: 0px
            margin: 0px
            background-color: transparent
            color: $project-global-color
            border: unset
            &:hover
                color: $periods-button-hover-text-color
            &:active
                color: $periods-button-active-text-color
                span
                    font: normal normal  14px/22px "Red Hat Display Bold"
        .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover
            color: rgba($project-global-color, 0.5)
            background-color: transparent
            border-color: transparent
.ant-picker-datetime-panel
    gap: 0 4px
    background-color: transparent
.ant-picker-panel-container
    background-color: transparent
.ant-picker-panel-container .ant-picker-panel
    border-bottom: unset
    border-top: unset
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner
    background: $periods-background-color 0% 0% no-repeat padding-box
    font: normal normal 14px/22px Red Hat Display
    color: $periods-selected-text-color
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner
    color: $periods-text-color
    &:hover
        font: normal normal 14px/22px "Red Hat Display Bold"
        color: $periods-selected-text-color
    &:active
        background: $periods-background-color 0% 0% no-repeat padding-box
        font: normal normal 14px/22px "Red Hat Display Bold"
        color: $periods-selected-text-color

.ant-picker-header
    height: 40px
    align-items: center

    & > button:hover
        color: $periods-button-hover-text-color

    & > button:active
        color: $periods-button-active-text-color

.ant-picker-range-arrow::after
    border-color: #f8f8fa #f8f8fa transparent transparent

.ant-picker-cell-in-view.ant-picker-cell-today.ant-picker-cell-range-start .ant-picker-cell-inner::before,
.ant-picker-cell-in-view.ant-picker-cell-today.ant-picker-cell-range-end .ant-picker-cell-inner::before,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after
    content: unset