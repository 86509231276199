.game-item
    background: transparent linear-gradient(180deg, #F8F8FA 0%, #F2F2F7 100%) 0% 0% no-repeat padding-box
    box-shadow: inset 0px 0px 1px #1917381F, 0px 1px 2px #1917381F
    border-radius: 12px
    max-height: 132px
    min-width: calc(50% - 8px)
    padding: 16px
    margin-bottom: 16px
    position: relative
    max-width: calc(50% - 16px)
    & > .ant-space-item
        &:nth-child(2)
            width: calc(100% - 196px)
            & > .ant-space-vertical
                width: 100%
                .ant-typography-ellipsis
                    width: 100%
    &:nth-child(2n - 1)
        margin-right: 16px
        
    img
        width: 100px
        height: 100px
        border-radius: 12px
        background-color: #00276614
        border: 1px solid #002766B3
        object-fit: cover
    .game-name
        font: normal normal 15px/19px "Red Hat Display Medium"
        color: $project-global-color
    .game-url
        font: normal normal 14px/19px "Red Hat Display"
        color: $project-global-color
        a
            font: normal normal normal 14px/20px "Red Hat Display"
            color: $link-color
            margin-left: 8px
    .game-actions
        position: absolute
        right: 16px
        top: 16px
        .anticon
            cursor: pointer
.line-vertical
    height: 16px
    border: 1px solid #D6D6DF
