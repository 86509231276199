.settings
  &--row
    width: 100%
    & .ant-form-item-control
      width: 100%
  & .btnGroup
    display: flex
    align-items: center
    justify-content: flex-start
    height: 90px
  
.mainBtn
  padding: 0px