.custom-switch
    flex-direction: row-reverse !important
    justify-content: flex-end
    .ant-form-item-control
        flex-grow: unset
        min-width: 61px
        max-width: 61px
    &.ant-form-item:not(.ant-form-item-has-error)
        margin-bottom: 24px
    & > div
        &:nth-child(1)
            padding: 0
            label
                padding-right: 8px
                display: block
                line-height: 26px
                color: $switch-lable-color
        
        &:nth-child(2)
            .ant-form-item-control-input
                min-height: 26px
                height: 26px
                max-width: 70px
                .ant-form-item-control-input-content
                    padding-left: 8px
                button
                    height: 12px
                    min-width: 36px
                    background-color: $switch-bg-color
                    &:focus
                        box-shadow: none
                    .ant-switch-handle
                        transform: translateY(-6px)
                        left: 0px
                        &::before
                            background-color: $switch-button-bg-color
                    &.ant-switch-checked
                        background-color: rgba(#002766, 0.14)
                        .ant-switch-handle
                            left: calc(100% - 20px)
                            &::before
                                background-color: #1890FF
    &:hover
        & > div
            &:nth-child(1)
                label
                    color: $switch-lable-hover-color
            &:nth-child(2)
                .ant-form-item-control-input
                    button
                        background-color: $switch-bg-hover-color
    &:active
        & > div
            &:nth-child(1)
                label
                    background: $switch-active-lable-bg-color 0% 0% no-repeat padding-box
                    border-top-right-radius: 13px
                    border-bottom-right-radius: 13px
            &:nth-child(2)
                .ant-form-item-control-input
                    background: $switch-active-lable-bg-color 0% 0% no-repeat padding-box
                    border-top-left-radius: 13px
                    border-bottom-left-radius: 13px
                    button
                        background-color: $switch-active-bg-color