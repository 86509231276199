@import "../base/_colors"

.formModal
  &.ant-modal
    &.loading

      & .ant-modal-close
        display: none

    & .ant-modal-header
      border-bottom: transparent

    & .ant-modal-title
      text-align: left
      font: 20px/23px "SF Pro Display Bold"
      letter-spacing: 0
      color: $popup-title-color
      margin: 5px 0

    & .ant-modal-body
      padding: 0 24px 24px 24px
      & .overflowContent
        min-height: 250px
        max-height: 70vh
        overflow-y: auto

    & .MainModal
      overflow: auto
      &.infoPopUp
        padding: 0 0 24px 0
        & .MainModal--parts:first-child
          border-right: 0
      &--parts
        padding: 0 24px

        & .withList
          height: 540px
          padding: 0 0 0 0

        // &:first-child
        //   border-right: 1px solid #DCDCE4

        & .ant-col.ant-form-item-label
          text-align: left
          font: 14px/22px "SF Pro Display Regular"
          letter-spacing: 0
          color: #191738
          padding: 0

      &--footer
        margin-bottom: 0 !important
        padding: 24px 0px 0 0

        & .ant-form-item-control-input-content
          display: flex
          align-items: center
          justify-content: flex-end

        & button
           margin: 0 4px
        & button.submit:hover
          & + .error_box
            display: block
        & .error_box
          position: absolute
          left: 100%
          top: 0
          display: none
          width: 266px
          background: #161829 0 0 no-repeat padding-box
          box-shadow: 0 0 26px #6371dd29
          border-radius: 4px
          opacity: 1
          padding: 13px 20px
          color: #E25F5F
.scroll-height
  max-height: calc(100vh - 289px)
  overflow-y: auto
  padding-right: 5px

.tab-modal
  &.formModal.ant-modal .MainModal--parts
    padding: 0