.gameImgUpload
    max-width: 162px
    .ant-form-item-explain
        position: absolute
        bottom: -38px
    &.ant-form-item:not(.ant-form-item-has-error)
        margin-bottom: 0
    .ant-upload.ant-upload-drag
        outline-offset: -1px
        outline: 1px dashed #00276680
        border-radius: 2px
    .show-by-hover
        display: none
    .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon
        color: $project-global-color
    .text
        font: normal normal 12px/22px Red Hat Display
        width: 65%
        margin: auto
        margin-top: 5px
    [role="alert"]
        white-space: nowrap
    & .uploadFileContent
        width: 162px
        height: 162px
        background-color: transparent
        justify-content: space-between
        border-radius: 2px
        .uploadFileContent--infoBox
            display: none
        .uploadFileContent--img
            width: 100%
            object-fit: cover
            height: 100%
        &.uploaded
            & .ant-upload.ant-upload-btn
                & .ant-upload-drag-container
                    & .uploadFileContent
                        flex-direction: unset
        & .ant-upload.ant-upload-drag.avatar-uploader,
        & .ant-upload.ant-upload-drag.preview-uploader
            width: 162px
            height: 162px
            & .ant-upload.ant-upload-btn
                & .uploadFileContent
                    display: flex
                    flex-direction: column
                    justify-content: flex-start
                    width: 162px
                    height: 162px
        &.active
            & .ant-upload.ant-upload-drag.avatar-uploader,
            & .ant-upload.ant-upload-drag.preview-uploader
                border: 2px solid $user-active-color
        
        &.blocked
            & .ant-upload.ant-upload-drag.avatar-uploader,
            & .ant-upload.ant-upload-drag.preview-uploader
                border: 2px solid $user-blocked-color

        &.inactive
            & .ant-upload.ant-upload-drag.avatar-uploader,
            & .ant-upload.ant-upload-drag.preview-uploader
                border: 2px solid $user-inactive-color

    .ant-upload.ant-upload-drag p.ant-upload-drag-icon
        margin-bottom: 3px
        margin-top: 40px
        font-size: 12px
        .anticon
            font-size: 30px
    .progress-bar
        border-radius: 12px
        p
            font-size: 12px
        .ant-progress-circle .ant-progress-text
            font-size: 12px
.top-block
    align-items: flex-start