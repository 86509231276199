@import "@assets/sass/base/_colors"
.transactions .mainTable .ant-table-thead > tr > th 
    white-space: nowrap
.dashboard
    min-height: calc(100% - 86px)
    display: flex
    .mainTable
        flex-direction: column
.dashboard .mainTable .ant-pagination.ant-table-pagination.ant-table-pagination-right,.dashboard .tableFooterLoader
    margin-top: 24px
.dashboard .bottom-bar
    justify-content: space-between
    width: 100%
.bottom-bar
    justify-content: space-between
    width: 100%
.dashboard-block
    min-height: calc(100% - 86px)
    .event-table
        min-height: calc(100vh - 500px)
    .operator-duration-block
        background: transparent linear-gradient(180deg, #F8F8FA 0%, #F2F2F7 100%) 0% 0% no-repeat padding-box
        box-shadow: inset 0px 0px 1px #1917381F, 0px 1px 2px #1917381F
        border-radius: 12px
    .operator-duration
        width: 100%
        justify-content: space-between
        min-height: 72px
        padding: 20px
        align-items: flex-start

        .right
            font: 14px/19px "Red Hat Display Regular"
            color: $project-icon-color
            text-transform: capitalize
            cursor: pointer
            padding: 4px 8px
            white-space: nowrap
            transform: translateY(-4px)
            border-radius: 13px
            &:hover
                color: $select-dropdown-title-color
            &:active
                background-color: $menu-item-bg-active-color
            &.ant-dropdown-open
                color: $select-dropdown-title-color

        & > .ant-space-item
            width: auto
            &:nth-child(2)
                width: 80%
    .horizontal-form
        
        .ant-form-item-label
            margin-right: 8px
                
.event-type
    text-transform: capitalize
    font: normal normal 15px/26px Red Hat Display
    letter-spacing: 0px
.dashboard
    .event-type
        border-radius: 14px
        opacity: 0.7
        /* padding: 5px; */
        display: inline-block
        height: 28px
        padding: 0px 9px
        font: normal normal 15px/26px Red Hat Display
        letter-spacing: 0px

        &.gamestart
            color: #0D3A9E
            background: #EDF5FF 0% 0% no-repeat padding-box
            border: 1px solid #8FC1EB
        &.winbet
            color: #389E0D
            background: #F6FFED 0% 0% no-repeat padding-box
            border: 1px solid #B7EB8F
        &.placebet
            color: #8D0D9E
            background: #FCEDFF 0% 0% no-repeat padding-box
            border: 1px solid #CB8FEB
        &.lostbet
            color: #9E0D0D
            background: #FFEDED 0% 0% no-repeat padding-box
            border: 1px solid #EB8F8F
        &.refundbet
            color: #9E710D
            background: #FFEDED 0% 0% no-repeat padding-box
            border: 1px solid #EB8F8F
form
    .event-type
        height: 26px
        padding: 0px 8px
        font: normal normal 14px/26px Red Hat Display
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item
        align-items: center