@import '../base/_colors'

.dealer-card-item
    display: flex
    flex-direction: column
    min-width: 374px
    width: calc(25% - 18px)
    min-height: 104px
    position: relative

    .header
        position: relative
        min-height: 50px
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        padding: 16px
        padding-left: 104px
        background: rgba(246, 255, 237, 1) 0% 0% no-repeat padding-box
        box-shadow: inset 0px 0px 1px rgba(25, 23, 56, 0.12), 0px 1px 2px rgba(25, 23, 56, 0.12)
        border-radius: 12px 12px 0px 0px

        .actions-block
            display: flex
            gap: 8px

        span:not([role="img"])
            font: 14px/19px "Red Hat Display Regular"
            letter-spacing: 0px
            color: rgba(82, 196, 26, 1)
            text-transform: capitalize
        span[role="img"]
            margin-left: auto
            cursor: pointer
            font-size: 18px
            color: $icon-color
            &:hover
                color: $icon-hover-color
            &:active
                color: $icon-focus-color
            &.last
                margin-left: 0 !important
                margin-right: 0 !important
            

                
        img, div.img
            position: absolute
            width: 72px
            height: 72px
            object-fit: cover
            border-radius: 50%
            left: 16px
            top: 16px
            border: 1px rgba(82, 196, 26, 1) solid
        div.img
            background-color: #fff

    .body
        background: transparent linear-gradient(180deg, rgba(248, 248, 250, 1) 0%, rgba(242, 242, 247, 1) 100%) 0% 0% no-repeat padding-box
        box-shadow: inset 0px 0px 1px rgba(25, 23, 56, 0.12), 0px 1px 2px rgba(25, 23, 56, 0.12)
        border-radius: 0px 0px 12px 12px
        height: calc(100% - 50px)
        .detals
            padding-left: 104px
            h4
                font: 17px/19px "Red Hat Display Medium"
                letter-spacing: 0px
                padding: 8px
                margin: 0
                padding-left: 0
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
            h5
                font: 14px/19px "Red Hat Display Regular"
                letter-spacing: 0
    &.blocked
        .header
            background: rgba(255, 241, 240, 1) 0% 0% no-repeat padding-box
            box-shadow: inset 0px 0px 1px rgba(25, 23, 56, 0.12), 0px 1px 2px rgba(25, 23, 56, 0.12)
            span:not([role="img"])
                color: rgba(245, 34, 45, 1)
            img
                border: 1px rgba(245, 34, 45, 1) solid

    &.inactive
        .header
            background: rgba(237, 245, 255, 1) 0% 0% no-repeat padding-box
            box-shadow: inset 0px 0px 1px rgba(25, 23, 56, 0.12), 0px 1px 2px rgba(25, 23, 56, 0.12)
            span:not([role="img"])
                color: rgba(124, 144, 165, 1)
            img
                border: 1px rgba(124, 144, 165, 1) solid
    &.loading
        .header
            background: #e8e8eb 0% 0% no-repeat padding-box
            box-shadow: inset 0px 0px 1px rgba(25, 23, 56, 0.12), 0px 1px 2px rgba(25, 23, 56, 0.12)
            span:not([role="img"])
                color: rgba(124, 144, 165, 1)
            img, .img
                border: 1px rgba(124, 144, 165, 1) solid
                