.operator-api-keys-content
    div.ant-typography
        margin-bottom: 0
        color: $project-global-color
        font: normal normal  17px/19px 'Red Hat Display Medium'
    table
        .ant-typography-expand, .ant-typography-edit, .ant-typography-copy
            margin-bottom: 0
            color: rgba($project-global-color, 0.7)
            font: normal normal 15px/21px Red Hat Display
        div.ant-typography
            font: normal normal 15px/21px Red Hat Display
    