// header
$logo-text-color:#0050B3
// button
$button-done-color: #1890FF
$button-cancel-color: #F8F8FA
$botton-border-color: #002766
$botton-default-color: #1890FF
$botton-default-shadow-color: #1890ff29
$button-default-background-color: #E6F7FF
$botton-default-bg-color: #E6F7FF
$botton-focus-color: #096DD9
$add-button-text-color: #1890FF
$csv-active-bg-color: #0050B3
$csv-hover-bg-color: #096DD9
$csv-bg-color: #1890FF
$csv-title-color: #fff
// upload
$img-background-color: rgba(#0050B3, 0.07)
$img-bg-color: #41479B
$img-info-bg-color: #203780#096DD9
$upload-button-color: rgba(#0050B3, 0.5)
$upload-button-hover-color: rgba(24, 144, 255, 1)
$upload-button-hover-bg-color: #E6F7FF
$upload-file-border-color:  rgba(#002766, 0.7)
// user
$user-active-color: #52c41a
$user-blocked-color: #f5222d
$user-inactive-color: #7c90a5
// icon
$with-icon-color: #D6D6DF
$icon-color: rgba(#002766, 0.7)
$icon-focus-color: #1890FF
$icon-hover-color: #002766
// select
$select-dropdown-bg-color: #E6F7FF
$select-dropdown-title-color: #1890FF
$select-selection-item-background: rgba(#1890FF, 0.07)
$select-selection-item-border: rgba(#1890FF, 0.07)
$select-selection-item-color: #002766
$select-hover-text-color: #1890FF
$select-hover-background-color: #E6F7FF
// periods
$periods-background-color: #E6F7FF
$periods-border-color: #1890FF
$periods-text-color: #002766
$periods-selected-text-color: #1890FF
$periods-button-hover-text-color: #1890FF
$periods-button-active-text-color: #1890ff
// menu
$menu-item-title-color: #002766
$menu-item-title-active-color: #002766
$menu-item-bg-active-color: #E6F7FF
// checkboxs
$checkbox-border-color: #002766
$checkbox-center-color: #002766
$checkbox-checked-color: #002766
$checkboxLabel-color: #002766
// popup
$popup-title-color: #002766
$reset-successful-popup-title-color: #00CE7E
$error-validation-color: #FF4D4F
$popup-shadow-color: #1A183B33
// inputs
$input-border-hover-color: #002766
$input-title-color: #002766
// scroll
$scroll-thumb: #DCDCE4
$scroll-thumb-hover: #E1E1E1
$scroll-track: #FFF
// main
$project-text-color: #002766
$project-icon-color: #002766
$content-title-color: rgba(0, 39, 102, 0.8)
$project-global-color: #002766
// statistics
$statistics-up-color: #00CE7E
$statistics-down-color: #AA193B
// pagination
$pagination-normal-color: rgba(#002766, 0.7)
$pagination-hover-color:#1890FF
$pagination-background-color: #E6F7FF
$pagination-border-color: #002766
$pagination-next-prev-color: #002766
$pagination-next-prev-hover-color: #1890FF
$pagination-option-border-color: #E5E6E8
$pagination-option-border-hover-color: #1890FF80
$pagination-option-color:  #002766
$pagination-option-hover-color: #E6F7FF
// tabs
$selected-tab-color: #002766
$selected-tab-border-color: rgb(0, 39, 102, 19%)
$selected-content-tab-color: #E6F7FF
$selected-content-tab-border-color: #1890FF
$selected-content-tab-lable-color: #1890FF

// modal
$modal-icon-normal-color: #002766
$modal-icon-hover-color: rgba(#1890FF, 0.7)
$modal-icon-active-color: #1890FF
//link color
$link-color: #0050B3
//switch
$switch-lable-color: #002766
$switch-button-bg-color: #A5B4CE
$switch-bg-color: rgba(#002766, 0.14)
$switch-bg-checked-color: rgba(#002766, 0.14)
$switch-lable-hover-color: #1890FF
$switch-bg-hover-color: rgba(#002766, 0.14)
$switch-active-lable-bg-color: #E6F7FF
$switch-active-bg-color: rgba(#1890FF, 0.14)
//badge
$badge-bg-color: #F5222D
$badge-color: #fff

//notification
$notification-title-color: #002766
$notification-description-color: rgba(#002766, 0.7)
$notification-popup-title-color: #002766

//operators
$operators-searched-color: #FAAD14