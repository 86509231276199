@font-face {
  font-family: 'SF Pro Display Medium';
  src: url(@assets/fonts/SFProDisplay-Medium.eot);
  src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
  url(@assets/fonts/SFProDisplay-Medium.eot?#iefix) format('embedded-opentype'),
  url(@assets/fonts/SFProDisplay-Medium.woff2) format('woff2'),
  url(@assets/fonts/SFProDisplay-Medium.woff) format('woff'),
  url(@assets/fonts/SFProDisplay-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}





@font-face {
  font-family: 'SF Pro Display Bold';
  src: url(@assets/fonts/SFProDisplay-Bold.eot);
  src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
  url(@assets/fonts/SFProDisplay-Bold.eot?#iefix) format('embedded-opentype'),
  url(@assets/fonts/SFProDisplay-Bold.woff2) format('woff2'),
  url(@assets/fonts/SFProDisplay-Bold.woff) format('woff'),
  url(@assets/fonts/SFProDisplay-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}




@font-face {
  font-family: 'SF Pro Display Regular';
  src: url(@assets/fonts/SFProDisplay-Regular.eot);
  src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
  url(@assets/fonts/SFProDisplay-Regular.eot?#iefix) format('embedded-opentype'),
  url(@assets/fonts/SFProDisplay-Regular.woff2) format('woff2'),
  url(@assets/fonts/SFProDisplay-Regular.woff) format('woff'),
  url(@assets/fonts/SFProDisplay-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url(@assets/fonts/Roboto/Roboto-Regular.eot);
  src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
  url(@assets/fonts/Roboto/Roboto-Regular.eot?#iefix) format('embedded-opentype'),
  url(@assets/fonts/Roboto/Roboto-Regular.woff2) format('woff2'),
  url(@assets/fonts/Roboto/Roboto-Regular.woff) format('woff'),
  url(@assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}





@font-face {
  font-family: 'Roboto Bold';
  src: url(@assets/fonts/Roboto/Roboto-Bold.eot);
  src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
  url(@assets/fonts/Roboto/Roboto-Bold.eot?#iefix) format('embedded-opentype'),
  url(@assets/fonts/Roboto/Roboto-Bold.woff2) format('woff2'),
  url(@assets/fonts/Roboto/Roboto-Bold.woff) format('woff'),
  url(@assets/fonts/Roboto/Roboto-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}




@font-face {
  font-family: 'Roboto Medium';
  src: url(@assets/fonts/Roboto/Roboto-Medium.eot);
  src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
  url(@assets/fonts/Roboto/Roboto-Medium.eot?#iefix) format('embedded-opentype'),
  url(@assets/fonts/Roboto/Roboto-Medium.woff2) format('woff2'),
  url(@assets/fonts/Roboto/Roboto-Medium.woff) format('woff'),
  url(@assets/fonts/Roboto/Roboto-Medium.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

// @font-face {
//   font-family: 'iconfont';
//   src: url(@assets/fonts/svgs/iconfont.eot);
//   src: local('icon-font'), local('iconfont'),
//   url(@assets/fonts/svgs/iconfont.eot?#iefix) format('embedded-opentype'),
//   url(@assets/fonts/svgs/iconfont.woff2) format('woff2'),
//   url(@assets/fonts/svgs/iconfont.woff) format('woff'),
//   url(@assets/fonts/svgs/iconfont.ttf) format('truetype');
//   font-style: normal;
// }

.icon {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
}
@font-face {
	font-family: "iconfont";
	src: url("@assets/fonts/svgs/iconfont.eot?e6b9527754becf3f5a1bfd14b567d3c5?#iefix") format("embedded-opentype"),
url("@assets/fonts/svgs/iconfont.woff2?e6b9527754becf3f5a1bfd14b567d3c5") format("woff2"),
url("@assets/fonts/svgs/iconfont.woff?e6b9527754becf3f5a1bfd14b567d3c5") format("woff");
}

// @import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@500;700&display=swap');