.notifications-tabs
    .ant-card-body
        padding: 0 12px
    .ant-card-meta-title
        font: normal normal  14px/18px "Red Hat Display Medium"
        color: $notification-title-color
    .ant-card-meta-description
        font: normal normal 11px/14px Red Hat Display
        color: $notification-description-color
    .ant-card-meta
        border: none
.notification-popup
    .ant-popover-inner-content
        padding-bottom: 24px
        padding-right: 24px
    .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav
        display: flex
    .ant-tabs-nav
        margin-left: 12px
    .ant-tabs-nav-wrap, .ant-tabs-nav-list
        width: 100%
    .ant-tabs-tab
        margin: 0
        width: 50%
        align-items: center
        justify-content: center
        padding-top: 0px
    .ant-tabs-nav-operations
        display: none !important
    .ant-tabs-ink-bar
        background: linear-gradient(182deg, #0050B3 0%, #1890FF 100%)
    .ant-popover-title
        font: normal normal bold 17px/22px Red Hat Display
        color: $notification-popup-title-color
        padding: 24px
        padding-bottom: 12px
        border: none
    .anticon-close
        position: absolute
        top: 33px
        right: 24px
.notification-bell
    cursor: pointer