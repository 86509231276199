.tabMode
  width: 100%
  text-align: center
  padding: 0 24px 24px 24px
  & .ant-radio-group.ant-radio-group-outline
    background: #F0F2F5 0 0 no-repeat padding-box
    border-radius: 4px
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    &.invalid
      background: #FFF1F0 0 0 no-repeat padding-box
      border: 1px solid #FF4D4F
      border-radius: 4px
    & .ant-radio-button-wrapper
      min-width: calc(100% / 2)
      text-align: center
      background: transparent
      border: transparent
      &:hover
        color: rgba($botton-default-color, 0.7)
      &:focus
        color: $botton-default-color
      &:before
        display: none
      &.invalid
        color: rgb(226, 95, 95, 0.9)
      &.ant-radio-button-wrapper-checked
        background: $selected-tab-color 0 0 no-repeat padding-box
        border: 0.5px solid $selected-tab-border-color
        border-radius: 4px
        text-align: center
        font: 14px/20px "Red Hat Display Medium"
        letter-spacing: 0
        color: #FFFFFF
  &.custom-tab-header
    margin-bottom: 0
    height: 28px
    & .ant-radio-group.ant-radio-group-outline
      & .ant-radio-button-wrapper
        &:hover
          color: $selected-content-tab-lable-color
        &:active
          background: $selected-content-tab-color 0 0 no-repeat padding-box
        &.ant-radio-button-wrapper-checked
          background: $selected-content-tab-color 0 0 no-repeat padding-box
          border-color: $selected-content-tab-border-color
          color: $selected-content-tab-lable-color
          &:nth-child(1)
            border-top-right-radius:  0px
            border-bottom-right-radius:  0px
          &:nth-child(2)
            border-top-left-radius:  0px
            border-bottom-left-radius:  0px
            


