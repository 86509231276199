.dealer-modal
    .ant-upload.ant-upload-drag
        outline-offset: -1px
    &.ant-modal .ant-modal-header
        padding: 24px
    .MainModal--footer
        margin-top: 0 !important
        border: unset !important
        padding: 12px 0px 0 24px !important
    .horizontal
        display: flex
        align-items: center
        gap: 16px
        align-content: flex-start
        & .ant-radio-group
            display: flex
            align-items: center
            justify-content: center
            & .ant-radio-wrapper
                max-width: 90px
                border-radius: 13px
                padding: 4px 8px
                line-height: normal
                display: flex
                align-items: center
                justify-content: center
                &:hover
                    background-color: $select-dropdown-bg-color
                    .ant-radio-inner
                        background-color: $select-dropdown-bg-color
                &:focus,&:focus-visible,&:focus-visible
                    & span
                        color: $botton-default-color
                & .ant-radio
                    top: 0
        &.name-lastname
            align-items: flex-start
            gap: 24px
            align-content: flex-start
            & > div
                max-width: calc(50% - 12px)
        .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner
            border-color: $user-active-color
        .ant-radio-inner::after
            background-color: $user-active-color
        .ant-radio-checked::after
            border: 1px solid $user-active-color
        .ant-radio-inner
            border-color: $user-active-color
        span.ant-radio + *
            color: $user-active-color
            
        .inactive
            .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner
                border-color: $user-inactive-color
            .ant-radio-inner::after
                background-color: $user-inactive-color
            .ant-radio-checked::after
                border: 1px solid $user-inactive-color
            .ant-radio-inner
                border-color: $user-inactive-color
            span.ant-radio + *
                color: $user-inactive-color

        .blocked
            .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner
                border-color: $user-blocked-color
            .ant-radio-inner::after
                background-color: $user-blocked-color
            .ant-radio-checked::after
                border: 1px solid $user-blocked-color
            .ant-radio-inner
                border-color: $user-blocked-color
            span.ant-radio + *
                color: $user-blocked-color
    .select-role-block
        position: relative
        .ant-form-item-required
            text-transform: lowercase
            & .capitalize::first-letter
                text-transform: uppercase
        .anticon-delete
            position: absolute
            right: 0px
            top: 5px
.role-drop-down
    max-width: unset !important
    min-width: 100px !important
    width: auto !important

.dealerImgUpload
    max-width: 104px
    .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon
        color: $project-global-color
    .text
        font: 12px/22px "Red Hat Display Regular"
    [role="alert"]
        white-space: nowrap
    & .uploadFileContent
        width: 102px
        height: 102px
        background-color: transparent
        justify-content: space-between
        border-radius: 50%
        .uploadFileContent--infoBox
            display: none
        .uploadFileContent--img
            width: 100%
            object-fit: cover
            height: 100%
        &.uploaded
            & .ant-upload.ant-upload-btn
                & .ant-upload-drag-container
                    & .uploadFileContent
                        flex-direction: unset
        & .ant-upload.ant-upload-drag.avatar-uploader,
        & .ant-upload.ant-upload-drag.preview-uploader
            width: 102px
            height: 102px
            border-radius: 50%
            & .ant-upload.ant-upload-btn
                & .uploadFileContent
                    display: flex
                    flex-direction: column
                    justify-content: flex-start
                    width: 98px
                    height: 98px
        &.active
            & .ant-upload.ant-upload-drag.avatar-uploader,
            & .ant-upload.ant-upload-drag.preview-uploader
                border: 2px solid $user-active-color
        
        &.blocked
            & .ant-upload.ant-upload-drag.avatar-uploader,
            & .ant-upload.ant-upload-drag.preview-uploader
                border: 2px solid $user-blocked-color

        &.inactive
            & .ant-upload.ant-upload-drag.avatar-uploader,
            & .ant-upload.ant-upload-drag.preview-uploader
                border: 2px solid $user-inactive-color

    .ant-upload.ant-upload-drag p.ant-upload-drag-icon
        margin-bottom: 3px
        margin-top: 27px
        font-size: 12px
        .anticon
            font-size: 24px
    .progress-bar
        border-radius: 12px
        p
            font-size: 12px
        .ant-progress-circle .ant-progress-text
            font-size: 12px

    