@import "@assets/sass/base/_colors"
.gamesreport .mainTable .ant-table-thead > tr > th 
    white-space: nowrap
.gamesreport
    min-height: calc(100% - 86px)
    display: flex
    .horizontal-form
        .ant-picker-range
            min-width: unset
    .mainTable
        flex-direction: column
        .ant-table-wrapper table .ant-table-tbody > tr > td:last-child
            text-align: left
.gamesreport .mainTable .ant-pagination.ant-table-pagination.ant-table-pagination-right,.gamesreport .tableFooterLoader
    margin-top: 24px
.gamesreport .bottom-bar
    justify-content: space-between
    width: 100%
.bottom-bar
    justify-content: space-between
    width: 100%
.gamesreport-block
    min-height: calc(100% - 86px)
    .event-table
        min-height: calc(100vh - 500px)
    .operator-duration-block
        background: transparent linear-gradient(180deg, #F8F8FA 0%, #F2F2F7 100%) 0% 0% no-repeat padding-box
        box-shadow: inset 0px 0px 1px #1917381F, 0px 1px 2px #1917381F
        border-radius: 12px
    .operator-duration
        width: 100%
        justify-content: space-between
        min-height: 72px
        padding: 20px
        align-items: flex-start

        .right
            font: 14px/19px "Red Hat Display Regular"
            color: $project-icon-color
            text-transform: capitalize
            cursor: pointer
            padding: 4px 8px
            white-space: nowrap
            transform: translateY(-4px)
            border-radius: 13px
            &:hover
                color: $select-dropdown-title-color
            &:active
                background-color: $menu-item-bg-active-color
            &.ant-dropdown-open
                color: $select-dropdown-title-color

        & > .ant-space-item
            width: auto
            &:nth-child(2)
                width: 80%
    .horizontal-form
        
        .ant-form-item-label
            margin-right: 8px
                


form
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item
        align-items: center
        overflow: hidden
        text-overflow: ellipsis
        max-width: 100%