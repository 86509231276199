#horizontal_Time
  display: flex
  align-items: flex-start
.horizontal-form
  flex-wrap: nowrap
  display: flex
  justify-content: flex-end
  flex-direction: row
  gap: 20px
  align-items: flex-start

  .submit
    margin-left: 8px
  button.miniBtn
    padding: 10px 22px
    min-width: 80px
  .ant-form-item:not(.ant-form-item-has-error)
    margin-bottom: 0px
    flex-wrap: nowrap
  .ant-form-item-label > label
    font: normal normal 12px/22px Red Hat Display
    color: $project-global-color
  input
    color: $project-global-color
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector
    height: 32px
  .ant-picker-range
    min-width: 400px
  .ant-form-item-control-input-content
    flex-wrap: nowrap
    display: flex
  .wrap-content
    display: flex
    gap: 20px
    flex-wrap: wrap
    .ant-form-item-control
      min-width: 200px
      max-width: 300px