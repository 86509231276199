@import "@assets/sass/base/_colors"
.settings-block
    .custom-tab-header
        width: fit-content
    .horizontal-form .ant-form-item-label
        margin-right: 8px
    .titleArea--title
        font: 32px/32px "SF Pro Display Bold"
    .titleArea
        padding: 29px 0px 29px 0
    .settings
        min-height: calc(100% - 90px)
    .ant-tabs, .ant-tabs-content
        height: 100%
    .tableFooterLoader
        margin-top: 24px
        margin-bottom: 24px
    .ant-pagination-total-text
        order: 20
    .header-tamplate-block
        min-height: calc(100% - 82px)
        .left
            width: 100%
            justify-content: space-between
        &.game-assignments
            .header
                & > .ant-space-item
                    &:nth-child(1)
                        width: 100%
        & > .ant-space-item
            &:nth-child(2)
                min-height: calc(100vh - 346px)
                
    .bottom-btn
        background: #1890FF 0% 0% no-repeat padding-box
        border-radius: 16px
        color: #fff
    .my-game
        background: #F8F8FA 0% 0% no-repeat padding-box
        border: 1px solid #F2F2F7
        padding: 24px
        min-height: calc(100% - 24px)
    .mainTable .ant-table-thead > tr > th
        &:last-child
            text-align: right
    .operator-api-keys-content
        .mainTable .ant-table-thead > tr > th
            &:last-child
                text-align: left

        .mainTable .ant-table-wrapper table .ant-table-tbody > tr > td:last-child
            text-align: left
    .header-tamplate-content
        background: transparent linear-gradient(180deg, #F8F8FA 0%, #F2F2F7 100%) 0% 0% no-repeat padding-box
        box-shadow: inset 0px 0px 1px #1917381F, 0px 1px 2px #1917381F
        border-radius: 12px
        min-height: calc(100vh - 346px)
        align-items: flex-start

        .operator-info
            width: 100%
            justify-content: space-between
            display: flex
            & > .ant-space-item
                padding: 20px
                &:nth-child(2)
                    width: auto
                    
.anticon.anticon-copy
    color: $project-global-color
.mainTable
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before
        content: unset