.jsoneditor
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #DCDCE4
    border-radius: 2px
    padding: 8px
    padding-top: 6px
    .jsoneditor-menu
        float: right
        max-width: 65%
        background-color: #ffffff
        border: unset
        height: 38px
        & > button
            background: #ffffff linear-gradient(180deg, #F8F8FA 0%, #F2F2F7 100%) 0% 0% no-repeat padding-box
            box-shadow: inset 0px 0px 1px #1917381F, 0px 1px 2px #1917381F
            border-radius: 4px
            padding: 0
            margin: 0px
            border: unset
            height: 34px
            width: 34px
            cursor: pointer
            &::before
                font-family: iconfont !important
                font-style: normal
                font-weight: normal !important
                vertical-align: top
                color: $project-global-color
                font-size: 18px
        .jsoneditor-expand-all
            border-top-right-radius: 0px
            border-bottom-right-radius: 0px
            box-shadow: inset 1px 0px 1px #1917381f, 0px 1px 2px #1917381f
            position: relative
            &::before
                content: "\f109"
            &::after
                content: ""
                height: 80%
                position: absolute
                right: -1px
                top: 10%
                width: 0px
                border-right: 1px solid #D6D6DF
        .jsoneditor-collapse-all
            border-top-left-radius: 0px
            border-bottom-left-radius: 0px
            box-shadow: inset -1px 0px 1px #1917381f, 1px 1px 2px #1917381f
            margin-right: 8px
            &::before
                content: "\f108"
        .jsoneditor-sort
            border-top-right-radius: 0px
            border-bottom-right-radius: 0px
            box-shadow: inset 1px 0px 1px #1917381f, 0px 1px 2px #1917381f
            position: relative
            &::before
                content: "\f10b"
            &::after
                content: ""
                height: 80%
                position: absolute
                right: -1px
                top: 10%
                width: 0px
                border-right: 1px solid #D6D6DF
        .jsoneditor-transform
            border-top-left-radius: 0px
            border-bottom-left-radius: 0px
            box-shadow: inset -1px 0px 1px #1917381f, 1px 1px 2px #1917381f
            margin-right: 8px
            &::before
                content: "\f102"
        .jsoneditor-undo
            border-top-right-radius: 0px
            border-bottom-right-radius: 0px
            box-shadow: inset 1px 0px 1px #1917381f, 0px 1px 2px #1917381f
            position: relative
            &::before
                content: "\f105"
            &::after
                content: ""
                height: 80%
                position: absolute
                right: -1px
                top: 10%
                width: 0px
                border-right: 1px solid #D6D6DF
        .jsoneditor-redo
            border-top-left-radius: 0px
            border-bottom-left-radius: 0px
            box-shadow: inset -1px 0px 1px #1917381f, 1px 1px 2px #1917381f
            margin-right: 8px
            &::before
                content: "\f104"
        .jsoneditor-search
            background: transparent linear-gradient(180deg, #F8F8FA 0%, #F2F2F7 100%) 0% 0% no-repeat padding-box
            box-shadow: inset 0px 0px 1px #1917381F, 0px 1px 2px #1917381F
            border-radius: 4px
            font: normal normal 14px/22px Red Hat Display
            letter-spacing: 0px
            color: rgba($project-global-color ,0.5)
            width: 268px
            height: 34px
            margin-top: 3px
            .jsoneditor-frame
                width: 100%
                display: flex
                align-items: center
                .jsoneditor-next
                    margin-left: auto
    .jsoneditor-navigation-bar
        float: left
        max-width: 35%
        background-color: #ffffff
        height: 34px
        border: unset
.jsoneditor-modal
    .pico-modal-header
        background: #fff
        color: $project-global-color
    .pico-close
        color: $project-global-color
    .jsoneditor-jmespath-label
        color: $project-global-color

// div.jsoneditor-tree
//     button.jsoneditor-button
//         &.jsoneditor-contextmenu-button
//             background-image: unset
//             &::before
//                 font-family: iconfont !important
//                 font-style: normal
//                 font-weight: normal !important
//                 vertical-align: top
//                 color: $project-global-color
//                 font-size: 18px
//                 content: "\f101"
//     &.jsoneditor-highlight
//         button.jsoneditor-button
//             &.jsoneditor-contextmenu-button
//                 &::before
//                     color: rgba($project-global-color, 0.2) 
// div.jsoneditor-tree button.jsoneditor-contextmenu-button:hover,
// div.jsoneditor-tree button.jsoneditor-contextmenu-button:focus,
// div.jsoneditor-tree button.jsoneditor-contextmenu-button.jsoneditor-selected,
// tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-contextmenu-button
//     &::before
//         color: $project-global-color


.jsoneditor-results
    color: $project-global-color
    display: flex
    align-items: center
    padding: 3px
.jsoneditor-contextmenu-root
    .jsoneditor-menu
        height: auto
        max-width: 100%
    .jsoneditor-transform
        padding: 0px !important
        box-shadow: unset !important
        margin-right: 0 !important
        display: flex