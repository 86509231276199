.custom-table
    display: flex
    .ant-table-tbody > tr, .ant-table-tbody > tr td
        background-color: #FFFFFF
    .actions-table
        height: 100%
        &.ant-table-wrapper .ant-table-tbody > tr:nth-child(2n), &.ant-table-wrapper .ant-table-tbody > tr:nth-child(2n) td
            background-color: #f7f8fa
    .data-table
        width: calc( 100% - 110px )
        margin-right: 10px
        &.ant-table-wrapper .ant-table-tbody > tr:nth-child(2n - 1), &.ant-table-wrapper .ant-table-tbody > tr:nth-child(2n - 1) td
            background-color: #f7f8fa
    .ant-table-cell-fix-right-first::after, .ant-table-cell-fix-right-last::after
        content: unset
    
   
.mainTable .custom-table .ant-table-wrapper .ant-table-tbody
    & > tr > td:not(.copyable-column)
        padding: 16px 13px 16px 16px
    
