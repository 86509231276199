.admin
    background: #F8F8FA 0% 0% no-repeat padding-box
    border: 1px solid #F2F2F7
    min-height: calc(100% - 64px)
    padding: 24px

.operator-api-keys-content
    &.manager
        .header
           & > .ant-space-item
                width: 100%
                .left
                    width: 100%
                    .operator-info
                        width: 100%
                        justify-content: space-between
                        padding-top: 20px
.operator-details-content
    .ant-popover-title
        font: normal normal 20px/20px "Red Hat Display Bold"
        color: $project-global-color
        padding: 24px
        padding-bottom: 0
        border-bottom: unset
    .ant-popover-inner-content
        padding: 24px 24px
    .ant-typography
        margin: 0
    .close-button
        position: absolute
        top: 25px
        right: 32px

.operator-details
    > div
        display: flex
        justify-content: space-between
        align-items: center
        height: 36px
        > span
            &:nth-child(1)
                text-transform: capitalize
                font: normal normal 12px/30px Red Hat Display
                color: $project-global-color
                margin-right: 10px
            &:nth-child(2), .operator-details-content .ant-typography, .ant-typography
                font: normal normal 12px/30px "Red Hat Display Medium"
                color: $project-global-color
    .top-bottom-border
        border: 1px solid rgba(0, 0, 0, 0.06)
        border-left: unset
        border-right: unset
    
    .mainTable .ant-table-wrapper table .ant-table-tbody > tr > td:last-child
        width: 100px
        min-width: unset
.operator-config-content
    .ant-popover-inner-content
        padding: 8px 0
        p
            margin-bottom: 0
            text-transform: capitalize
            padding: 6px 16px
            cursor: pointer
            &:hover
                color: $select-dropdown-title-color
            &:active
                color: $select-dropdown-title-color
                background-color: $menu-item-bg-active-color
