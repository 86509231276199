.game-form
    .formModal.ant-modal .MainModal--footer
        padding: 0
    &-body
        margin-bottom: 24px
        width: 100%
        align-items: stretch
        .left, .right
            background: #F8F8FA 0% 0% no-repeat padding-box
            border: 1px solid #F2F2F7
            border-radius: 4px
            padding: 24px
            padding-bottom: 0px
            width: 270px
            height: 100%
            .ant-form-item:not(.ant-form-item-has-error)
                margin-bottom: 24px
        & > div
            background: #FFFFFF 0% 0% no-repeat padding-box

            .img-block
                padding: 12px
                border: 1px solid #DCDCE4
                border-radius: 2px
                max-height: 186px
                height: 100%
       
            .uploadFileContent
                .text
                    color: rgba($project-global-color, 0.5)
                    &::first-letter
                        text-transform: uppercase
        .right-block
            .ant-row
                &:nth-child(3)
                    margin-bottom: 0px
        .image-buttons
            button
                background-color: transparent
                color: rgba($project-global-color, 0.5)
                padding-left: 7px
                padding-right: 7px
                &:hover, &:focus
                    color: $upload-button-hover-color
                &:active
                    color: $upload-button-hover-color
                span
                    margin-right: 8px
            .ant-space-item
                &:nth-child(1)
                    button
                        position: relative
                        &::after
                            content: ""
                            height: 100%
                            position: absolute
                            right: -6px
                            top: 0px
                            width: 1px
                            background-color: #D6D6DF
            &.add
                justify-content: center
                width: 100%
                & > .ant-space-item
                    &:nth-child(2)
                        display: none
                    &:nth-child(1)
                        button
                            &::after
                                content: unset
                        
        .image-types
            color: rgba($project-global-color, 0.5)
            font: normal normal 12px/22px Red Hat Display
#game-form
    .top
        background: #F8F8FA 0% 0% no-repeat padding-box
        border: 1px solid #F2F2F7
        border-radius: 4px
        padding: 24px
        margin-bottom: 8px
                    
                

                    
.add-button
    font-size: 25px
    position: absolute
    bottom: 0px
    left: 25px
    z-index: 1
    cursor: pointer
    margin-bottom: 0px
    .anticon
        color: rgba($project-text-color, 0.7)
        &:hover
            color: $project-text-color
        &:focus, &:active
            color: $icon-focus-color