@import "@assets/sass/base/_colors"

.top-block
    & > .ant-space-item
        width: 392px
    .top-block-body
        width: 100%
        height: 100%
        justify-content: space-between
        & > .ant-space-item
            &:nth-child(2)
                height: 100%
                
.game-list
    min-height: calc(100% - 64px)
    padding: 24px
    background: #F8F8FA 0% 0% no-repeat padding-box
    border: 1px solid #F2F2F7
    width: 100%
    .title
        text-transform: capitalize
        font: normal normal bold 17px/19px Red Hat Display
        color: $project-global-color
.games
    min-height: calc(100% - 86px)
    .tableFooterLoader
        margin-top: 24px
        margin-bottom: 24px
    .ant-pagination-total-text
        order: 20
.games-block
    min-height: calc(100vh - 95px)