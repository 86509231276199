.uploadFileContent
    position: relative
    background: #41479B 0% 0% no-repeat padding-box
    display: flex
    flex-direction: column
    justify-content: center
    max-height: 400px
    overflow: hidden
    height: 100%
    border-radius: 12px
    .show-by-hover
        position: absolute
        opacity: 0
        width: 100%
        bottom: 0
        height: 28px
        background-color: rgba($project-global-color, 0.7)
        color: #fff
        font: 15px/20px "Red Hat Display Medium"
        display: flex
        align-items: center
        justify-content: center
        &:hover
            color: #fff
        &:focus,&:focus-within,&:focus-visible,&:active
            color: $botton-default-color
    &:hover
        & .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon
            color: rgba($icon-focus-color, 0.7)
        & .text
            color: rgba($icon-focus-color, 0.7)
    &:focus
        & .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon
            color: $icon-focus-color
        & .text
            color: $icon-focus-color
    & .progress-bar
        height: 100%
        width: 100%
        align-items: center
        display: flex
        justify-content: center
        flex-direction: column
        background-image: url(@assets/img/loadingImg.svg)
        .ant-progress-inner
            border: unset
            .ant-progress-circle-trail
                stroke: transparent
        .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path
            stroke: #ffffff
        .ant-progress-circle .ant-progress-text, p
            color: #ffffff !important
        
    &.uploaded
        & .ant-upload.ant-upload-drag
            border: transparent
        & .ant-upload-drag-container
           vertical-align: top !important
    &--img
        width: 100%
        object-fit: fill
    &--infoBox
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        background:  #191738 0% 0% no-repeat padding-box
        border-radius: 0 0 2px 2px
        padding: 16px 18px
        &--title
            display: none
            text-align: left
            font: 14px/18px "SF Pro Display Regular" 
            letter-spacing: 0
            color: #ffffff
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            width: 235px
        &--btnGroup
            & button
                background: transparent
                border: transparent
                padding: 5px
                cursor: pointer
                & .text
                    display: none
                &:hover,&:active,&:focus
                   background: transparent




    & .ant-upload.ant-upload-drag .ant-upload
        padding: 0
        // background: #41479B 0% 0% no-repeat padding-box
    .ant-upload-drag-container
        // background: #41479B 0% 0% no-repeat padding-box
        width: 100%
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center



.categoryImgUpload
  svg:not(.ant-progress-circle)
    width: 30px
    height: 30px
    margin-top: 13px
  .text
    font-size: 9px

  & .uploadFileContent
    background-color: transparent
    justify-content: space-between
    &.uploaded
        & .ant-upload.ant-upload-drag.avatar-uploader,
        & .ant-upload.ant-upload-drag.preview-uploader
          & .ant-upload.ant-upload-btn
             & .ant-upload-drag-container
                & .uploadFileContent
                  // flex-direction: unset
    & .ant-upload.ant-upload-drag.avatar-uploader,
    & .ant-upload.ant-upload-drag.preview-uploader
      // background: #41479B 0 0 no-repeat padding-box !important
      outline-offset: -5px
      width: 104px
      height: 104px
      & .ant-upload.ant-upload-btn
        & .ant-upload-drag-container
          & .uploadFileContent
            display: flex
            flex-direction: column
            justify-content: flex-start



    &--img
      width: 104px
      height: 104px
      object-fit: cover
      padding: 0px
      // background: #41479B 0 0 no-repeat padding-box
      outline: 1px dashed #fff
    &--infoBox
      background: transparent
      width: calc(100% - 104px)
      display: flex
      flex-direction: unset
      flex-wrap: wrap
      padding: 0 14px
      margin: 0
      justify-content: flex-end
      align-items: flex-end
      &--title
        color: #41479B
      &--btnGroup
        display: flex
        flex-wrap: wrap
        width: 100%
        & button
          width: 100%
          background: #F0F2F5 0 0 no-repeat padding-box
          border-radius: 2px
          opacity: 1
          display: flex
          align-items: center
          justify-content: center
          flex-direction: row-reverse
          margin: 4px
          width: 188px
          &.delete
            display: none
          &:hover,&:active,&:focus
            color: #41479B
          & .text
            display: block
          & img
            margin: 5px
    & .ant-upload.ant-upload-btn
      background: transparent
      & .ant-upload-drag-container
        background: #FFFFFF 0% 0% no-repeat padding-box
        border-radius: 12px
