.delete-confirm-popup
    width: 40px
    height: 40px
    img
        margin-top: 8px
        width: 40px
        height: 40px
    h3
        font: normal normal 23px/23px Red Hat Display
        color: #F5222D
        text-transform: capitalize
    p
        font: normal normal 15px/20px Red Hat Display
        color: $project-global-color
    .body
        display: flex
        justify-content: center
        text-align: center
    .MainModal--footer
        justify-content: right
        .ant-form-item-control-input-content
            justify-content: right
            display: flex
            .submit
                margin-left: 8px
            .default
                margin-left: auto
    .ant-modal-close-x
        display: none