.round-details-content
    .ant-popover-title
        font: normal normal 20px/20px "Red Hat Display Bold"
        color: $project-global-color
        padding: 24px
        padding-bottom: 0
        border-bottom: unset
    .ant-popover-inner-content
        padding: 24px 24px
    .ant-typography
        margin: 0
    .close-button
        position: absolute
        top: 25px
        right: 32px

.round-details
    > div
        display: flex
        justify-content: space-between
        align-items: center
        height: 36px
        > span
            &:nth-child(1)
                text-transform: capitalize
                font: normal normal 12px/30px Red Hat Display
                color: $project-global-color
                margin-right: 10px
            &:nth-child(2), .round-details-content .ant-typography, .ant-typography
                font: normal normal 12px/30px "Red Hat Display Medium"
                color: $project-global-color
    .top-bottom-border
        border: 1px solid rgba(0, 0, 0, 0.06)
        border-left: unset
        border-right: unset
    
    .mainTable .ant-table-wrapper table .ant-table-tbody > tr > td:last-child
        width: 100px
        min-width: unset

.card-section
    border: 1px solid #ccc
    padding: 10px
    & .cards-container
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        align-items: center
        & h2
            font-size: 16px
            font-weight: bold
            width:100%
            margin-top: 15px
        & .c-img
            margin-right: 4px
            height: 60px
        & .winner
            & img
                height: 45px
    &.baccarat-cards 
        & .cards-container
            & .c-img:nth-child(3n)
                transform: rotate(90deg)
                margin-left: 10px