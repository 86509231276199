.operators-list-popup
    background: #FFFFFF 0% 0% no-repeat padding-box
    box-shadow: 0px 0px 15px #1A183B33
    border-radius: 4px
    img
        width: 24px
        height: 24px
        border-radius: 50%
        object-fit: cover
    .ant-menu-item .ant-menu-item-icon + span
        margin-left: 12px
    .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-vertical .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after
        display: none
    .search-block
        padding: 16px 16px 0px 16px
    .ant-menu
        max-height: calc(100vh - 500px)
        overflow-y: auto
    .ant-menu-item-selected
        font: normal normal 14px/21px "Red Hat Display Medium" !important
    .search-sub-string
        color: $operators-searched-color
        text-transform: initial
    .ant-menu-item
        text-transform: initial
    .ant-menu-title-content
        margin-left: 10px
.operator-item
    img
        width: 32px
        height: 32px
        border-radius: 50%
        object-fit: cover
    div
        white-space: nowrap
.client-list-input
    display: inline-block
    max-width: 100%
    .operator-item
        max-width: 100%
        & > .ant-space-item
            width: fit-content
            &:nth-child(2)
                max-width: 59%