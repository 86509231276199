.ant-layout-sider-children
    display: flex
    flex-direction: column
    
    .bar-trigger
        text-align: right
        padding-right: 19px
        margin-top: auto
        margin-bottom: 0px
        padding-bottom: 10px
        padding-top: 10px
        background-color: #EFEFEF
.ant-page-header.has-breadcrumb
    padding: 24px
    margin: 24px 24px 0 24px
    background: #fff
    .ant-breadcrumb
      .ant-breadcrumb-link
         text-align: left
         font: 32px/38px "SF Pro Display Bold"
         letter-spacing: 0
         color: #19173830

.operator-dropdown.ant-select-dropdown,
.games-dropdown.ant-select-dropdown
    text-transform: none