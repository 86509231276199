@import "../../assets/sass/base/_colors"

.site-layout-background.ant-layout-header
    padding: 0
    line-height: 54px
    box-shadow: 0 0 20px #1A183B33
    position: relative
    z-index: 1

    .search
        margin-left: auto
    
    div
        display: flex
        flex-direction: row
        height: inherit

    .user-account, .search
        width: 72px
        border-width: 0
        border-left-width: 1px
        border-color: transparent
        border-style: solid
        display: flex

        div
            margin: auto

    .logo-site
        flex: 0 0 56px
        max-width: 56px
        min-width: 56px
        width: 56px
        text-align: left
        font:  16px/22px "SF Pro Display Bold"
        letter-spacing: 0
        color: #41479B
        opacity: 1
        position: relative
        display: none
        &.show
            display: flex
        &:before
            content: ""
            position: absolute
            right: 0
            top: 0
            bottom: 0
            margin: auto
            width: 1px
            height: 70%
            background-color:  #D6D6DF
        
        img
            width: 18px
            height: 18px
            cursor: pointer
            margin: auto
    .time-block
        margin-left: 24px
        margin-right: auto
        font: 12px/22px "SF Pro Display Regular"
        letter-spacing: 0
        color: $project-global-color
        span
            text-transform: capitalize
            font: 26px/24px "Red Hat Display Regular"
            color: $logo-text-color
            font-weight: 500
        img
            margin-right: 8px
    .notification
        .ant-badge
            display: flex
            align-items: center
            img
                height: 18px
            .ant-badge-count
                background: $badge-bg-color 0% 0% no-repeat padding-box
                border-radius: 8px
                height: 16px
                span
                    color: $badge-color
                    font-size: 9px
                    font-family: "Red Hat Display Bold"
.logoTooltip
    left: 10px !important
.logoDropDown
    width: 600px